import React, { useState, useEffect } from "react";
import {Form, Formik} from "formik";
import axios from "axios";
import tasInitialValues from "../validation/tasInitialValues";
import tasValidationSchema from "../validation/tasValidationSchema";
import TasStep1 from "./step/TasStep1";
import TasStep2 from "./step/TasStep2";
import TasStep3 from "./step/TasStep3";
import Stepper from "../../common/ui/Stepper";
import Spinner from "../../common/ui/Spinner";
import { withNamespaces } from 'react-i18next';
import ErrorFocus from "../../common/ErrorFocus";
import TasStep4 from "./step/TasStep4";
import Summary from "./step/Summary";
import Confirmation from "./step/Confirmation"; 

function Tas({t, i18n}) {
    const postUrl = process.env.REACT_APP_POST_URL ?? '';
    const apiKey = process.env.REACT_APP_API_KEY ?? '';
    const [activeStep, setActiveStep] = useState(0);
    const [confirmation, setConfirmation] = useState(0);
    const [status, setStatus] = useState(0);
    const [loading, setLoading] = useState(false);

    const choices1 = [
        t('choice1Label')
    ];

    const choices2 = [
        t('choice2Label')
    ];

    const choices3 = [
        t('choice3Label')
    ];

    const choices4 = [
        t('choice4Label')
    ];

    const currentValidationSchema = tasValidationSchema[activeStep];
    const steps = [
        t('stepper1Label'),
        t('stepper2Label'),
        t('stepper3Label'),
        t('stepper4Label'),
        t('stepper5Label')
    ];

    const [lastStep] = useState(steps.length - 1);
    const [tasFormChoice, setTasFormChoice] = useState('');

    const renderStepComponent = () => {
        switch (activeStep) {
            case 0:
                return <TasStep1 translation={t} i18n={i18n} setTasFormChoice={setTasFormChoice}/>;
            case 1:
                return <TasStep2 translation={t}/>;
            case 2:
                return <TasStep3 translation={t}/>;
            case 3:
                return <TasStep4 translation={t} i18n={i18n}/>;
            case 4:
                return <Summary translation={t}/>;
            default:
                return <div>Not Found</div>;
        }
    }

    const sendData = async (values) => {
        try {
            await axios.post(postUrl, JSON.stringify(values), {
                headers: {
                    'Content-Type': 'application/json',
                    'Ocp-Apim-Subscription-Key': apiKey,
                    'Ocp-Apim-Trace': true,
                    'X-Azure-Allowed': 'on'
                }
            }).then(res => {
                setStatus(res.status);
                if (res.status === 200) {
                    setLoading(false);
                    setConfirmation(1);
                } else {
                    setLoading(false);
                    setConfirmation(1);
                }
            }).catch(err => {
                setStatus(500);
                setLoading(false);
                setConfirmation(1);
            })
        } catch (err) {
            setStatus(500);
            setLoading(false);
            setConfirmation(1);
        }
    } 

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    }

    const buildDocuments = async (files) => {
        let allFiles = [];
        files.forEach(currentFile => {
            let file = {};
            file['type'] = currentFile.type;
            file['size'] = currentFile.size;
            file['name'] = currentFile.name;

            let reader = new FileReader();
            reader.onload = function () {
                file['value'] = reader.result;
                allFiles.push(file);
            };
            reader.readAsDataURL(currentFile);
        })
        return allFiles;
    }

    const handleSubmit = async (values, actions) => {
        setTasFormChoice(values['tasFormChoice']);
        if (activeStep === lastStep) {
            setLoading(true);
            values['language'] = i18n.language ?? 'de';
            window.parent.postMessage({
                event: "form_submit",
                form_policy: values['tasFormChoice'],
                form_step: activeStep
            }, "*");
            sendData(values);
        } else {
            if (activeStep === lastStep - 1) {
                values['tasDocument2'] = await buildDocuments(values['tasDocument']);
            }
            window.parent.postMessage({
                event: "form_step",
                form_policy: values['tasFormChoice'],
                form_step: activeStep
            }, "*");
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'instant'});
            if ('parentIFrame' in window) {
                window.parentIFrame.scrollTo(0, 0);
            }
        }, 0);
    }, [activeStep]);

    return (
        <>
            {activeStep !== 0 && activeStep !== lastStep && confirmation === 0 &&
                <div className={'title-choice'}>
                    <p>{tasFormChoice}</p>
                </div>
            }
            {confirmation === 0 &&
                <Stepper steps={steps} activeStep={activeStep}/>
            }
            <div className={'app-container'}>
                <Formik
                    initialValues={tasInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={handleSubmit}
                >
                    {formikBag => (
                        <>
                            {confirmation === 0 &&
                                <>
                                    <Form className={'form-container'}>
                                        {renderStepComponent()}
                                        <ErrorFocus/>
                                        <div className={'item'}>
                                            <div className={'buttons'}>
                                                {activeStep > 0 &&
                                                    <button
                                                        className={'button' + (loading ? ' buttonDisabled' : '')}
                                                        type="button"
                                                        onClick={handleBack}>
                                                        {t('backLabel')}
                                                    </button>
                                                }
                                                {(tasFormChoice === ''
                                                        || choices1.includes(tasFormChoice)
                                                        || choices2.includes(tasFormChoice)
                                                        || choices3.includes(tasFormChoice)
                                                        || choices4.includes(tasFormChoice)) &&
                                                    <button
                                                        className={'button' + (loading ? ' buttonDisabled' : '')}
                                                        type="submit">
                                                        {activeStep !== lastStep ? t('nextLabel') : t('sendLabel')}
                                                    </button>
                                                }
                                            </div>
                                        </div>

                                    </Form>

                                </>
                            }
                            {confirmation === 1 &&
                                <div className={'container'}>
                                    <Confirmation status={status} translation={t} i18n={i18n}/>
                                </div>
                            }
                        </>
                    )
                    }
                </Formik>
            </div>

            {
                loading &&
                <div className={'container'}>
                    <Spinner/>
                </div>
            }
        </>
    )
}

export default withNamespaces()(Tas)
